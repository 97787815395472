import { RestfulProvider } from "restful-react";
import { SnackbarProvider } from 'notistack'
import { ConfirmProvider } from "material-ui-confirm";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'

import { CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import { MatxTheme } from './components';
import useAuth from 'app/hooks/useAuth';
import routes from './routes';

/*
 SEE: 
  https://github.com/contiamo/restful-react/issues/248
  https://codesandbox.io/s/clever-curran-kgg0y?file=/src/App.tsx:174-270
*/
const { origin } = window.location
const default_rest_api = `${origin}`;
const rest_api = process.env.REACT_APP_REST_BASE || default_rest_api;

const App = () => {
  const content = useRoutes(routes);
  const { user } = useAuth();

  return (
    <RestfulProvider 
      base={rest_api} 
      requestOptions = { user?.access_token 
        ? {headers: {Authorization: `Bearer ${user.access_token}`}} 
        : undefined }
      >
      <MatxTheme>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <ConfirmProvider>
            <SnackbarProvider
              dense
              autoHideDuration={5000}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              {content}
            </SnackbarProvider>
          </ConfirmProvider>
        </LocalizationProvider>
      </MatxTheme>
    </RestfulProvider>
  )
};

export default App;
