import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

console.log('Exporting i18n');

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'SE',
    debug: false,

    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase')  return value.toUpperCase();
        if (format === 'lowercase')  return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      }
    },
  });

export default i18n;