import React, { useState } from 'react';

import { Grid, Box } from '@mui/material';
import { Revision, Documents } from 'app/BRF';
import { RevisionJobQueue, RevisionState, BaseCard, RevisionQueue } from 'app/BRF';
import { i18capitalize } from 'app/utils/i18utils';

/*
  Would like to send revision_id as prop, then get up the correct state
  queue and details on the revision.

  To get the correct queue we need the revisions state as initial value
  to the setDetails

  We also need the revision data to put into setRevision.

  => So, probably send in the revision and use it as basis.
*/

const RevisionProcess = (props) => {
  const { title, revision=null } = props;
  const [ stateQueue, selectStateQueue ] = useState(revision ? revision.state : 'REVISION_STARTED');
  const [ refresh, triggerRefresh ] = useState(0);

  const QueueExplorer = React.memo(() => {
    const [ showRevision, setRevision ] = useState(revision);

    return (
      <>
        <Grid item lg={7} md={7} sm={12} xs={12}>
          <div style={{height: '100%'}}>
            <RevisionQueue 
              key={refresh}
              title={i18capitalize(`revision_states.${stateQueue}`, stateQueue)}
              states={[stateQueue]}
              setRevision={setRevision} 
              fixedList={true}
              pagesize={6}
            />
          </div>
        </Grid>

        {showRevision !== null ? (
          <>
            <Grid item lg={7} md={7} sm={12} xs={12}>
              <Revision key={showRevision.id} revision={showRevision} refresh={refresh} triggerRefresh={triggerRefresh} />
            </Grid>

            <Grid item lg={5} md={5} sm={12} xs={12}>
              <BaseCard title={i18capitalize('customer.documents', 'documents')}>
                  <Documents 
                    key={refresh}
                    parent_id={showRevision.company_id} 
                    usage_type='company' 
                    read_only={false}
                />  
              </BaseCard>
              <Box pt={1}/>
              <BaseCard title={i18capitalize('revision documents', 'revision documents')}>
                <Documents parent_id={showRevision.id} usage_type='revision' read_only={showRevision.state === 'REVISION_COMPLETE'} />  
              </BaseCard>
              <Box pt={1}/>
              <BaseCard title={i18capitalize(`revision_states.${showRevision?.state}`, 'No revisions')} >
                {showRevision && <RevisionState revision={showRevision} read_only={true} events_only={true} />}
              </BaseCard>
            </Grid>
          </>
          ) : (<div/>)
        }
      </>
    );
  });

  return (
    <>
      <Grid container spacing={3}>
        <RevisionJobQueue key={refresh} title={title} setDetails={selectStateQueue}/>
        <QueueExplorer />
      </Grid>
    </>
  );
};

export default RevisionProcess;
