import { parseISO } from 'date-fns'
import { createElement } from "react";
//import ReactDOMServer from "react-dom/server";

import { ElementFactory, Question, Serializer } from "survey-core";
import { SurveyQuestionElementBase, ReactQuestionFactory } from "survey-react-ui";

import { DatePicker as DatePickerComponent} from '@mui/x-date-pickers/DatePicker';

const CUSTOM_TYPE = "datepicker";

export class QuestionDatePickerModel extends Question {
    getType() {
      return CUSTOM_TYPE;
    }
    get datePickerType() {
      return this.getPropertyValue("datePickerType");
    }
    set datePickerType(val) {
      this.setPropertyValue("datePickerType", val);
    }
}

export function registerDatePicker() {
    ElementFactory.Instance.registerElement(CUSTOM_TYPE, (name) => {
        return new QuestionDatePickerModel(name);
    });
}

Serializer.addClass(
  CUSTOM_TYPE,
  [{
    name: "datePickerType",
    default: "Standard",
    choices: ["Standard"],
    category: "general",
    visibleIndex: 2 
  }],
  function () {
    return new QuestionDatePickerModel("");
  },
  "question"
);

export class SurveyQuestionDatePicker extends SurveyQuestionElementBase {
  constructor(props) {
    super(props);
    this.state = { value: this.question.value };
  }
  get question() {
    return this.questionBase;
  }
  get value() {
    return this.question.value;
  }
  get type() {
    return this.question.datePickerType;
  }
  handleDateChange = (data) => {
    this.question.value = data.toISOString();
  };

  renderDatePicker(type) {
    switch (type) {
      case "Standard": {
        return (
          <DatePickerComponent value={parseISO(this.value)} onChange={this.handleDateChange} disabled={this.isDisplayMode} />
        );
      }
      default:
        return <div>Unknown type</div>;
    }
  }

  renderElement() {
    return (
      <div style={this.style}>
        {this.renderDatePicker(this.type)}
      </div>
    );
  }
}

ReactQuestionFactory.Instance.registerQuestion(CUSTOM_TYPE, (props) => {
  return createElement(SurveyQuestionDatePicker, props);
});
