export const authRoles = {
  sa: ['SA'], // Only Super Admin has access
  admin: ['SA', 'ADMIN'], // Only SA & Admin has access
  editor: ['SA', 'ADMIN', 'EDITOR'], // Only SA & Admin & Editor has access
  guest: ['SA', 'ADMIN', 'EDITOR', 'GUEST'] // Everyone has access
};

/*
  We want to have roles based on group membership.
    consult, manager, revisor, system

  The authRoles is only used in routes 'auth: authRoles.admin' ...
  So, we can useXXX user object, and update roles based on the groups 
*/