import { createRoot } from 'react-dom/client';
import { useRef, useEffect } from 'react';
import { BrowserRouter, useNavigate  } from 'react-router-dom';
import axios from 'axios';

import './i18n';
import App from './app/App';
import './app/App.css';
import { AuthProvider } from './app/contexts/JWTAuthContext';
import { SettingsProvider } from './app/contexts/SettingsContext';
import { ConfigProvider } from 'app/contexts/ConfigContext';
import { NewsProvider } from 'app/contexts/NewsContext';
import { AuthMethodsProvider } from 'app/contexts/AuthMethodsContext';
import { i18sentence } from 'app/utils/i18utils';

import { StyledEngineProvider } from '@mui/material';
import * as serviceWorker from './serviceWorker';

// third party style
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import 'react-dropzone-uploader/dist/styles.css';
import { enqueueSnackbar } from 'notistack'

// setup axios base rest api url
const { origin } = window.location
const default_rest_api = `${origin}`;
const rest_api = process.env.REACT_APP_REST_BASE || default_rest_api;
axios.defaults.baseURL = rest_api;

/*
  Default to go to login on 401 response to a get()
  But, /profile always fails on non-logged in -> loop
  So, use a {ignoreGlobalCatch: true,} in config object

  Ref:
    https://daily.dev/blog/a-guide-to-writing-clean-api-calls-using-axios
    https://axios-http.com/docs/interceptors
*/
const ResponseInterceptor = () => {
  const navigate = useNavigate()
  const interceptorId = useRef(null);

  useEffect(() => {
    interceptorId.current = axios.interceptors.response.use(undefined, (error) => {
      switch (error.response.status) {
        case 401:
          if(!error.config.ignoreGlobalCatch){
            navigate('/login');
            enqueueSnackbar(i18sentence('msg.login again', 'Your session timed out, please login again'), {variant: "error"});
          }
          break;

        default:
          return Promise.reject(error);
      }
    });

    return () => {
      axios.interceptors.response.eject(interceptorId.current);
    };
  }, [navigate]);

  return null;
};

const root = createRoot(document.getElementById('root'));

root.render(
  <StyledEngineProvider injectFirst>
    <BrowserRouter>
      <SettingsProvider>
        <AuthMethodsProvider>
          <AuthProvider>
            <ConfigProvider>
              <NewsProvider>
                <App />
              </NewsProvider>
            </ConfigProvider>
            <ResponseInterceptor/>
          </AuthProvider>
        </AuthMethodsProvider>
      </SettingsProvider> 
    </BrowserRouter>
  </StyledEngineProvider>
);

// for IE-11 support un-comment cssVars() and it's import in this file
// and in MatxTheme file

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
