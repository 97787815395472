import { red } from '@mui/material/colors';
import { components } from './components';

const themeOptions = {
  typography: {
    fontSize: 14,
    body1: { fontSize: '14px' },
  },

  status: { danger: red[500] },
  components: { ...components },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 800,
      st: 1000,
      lg: 1200,
      xl: 1536, 
    }
  }, 
};

export default themeOptions;
