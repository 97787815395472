import { useState } from "react";
import i18next from 'i18n'

import { Model, Serializer } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/i18n/swedish";
import "survey-core/i18n/norwegian";
import "survey-core/i18n/english";
import "survey-core/defaultV2.min.css";

// Themes
import { BorderlessLight } from "survey-core/themes/borderless-light";

import { enqueueSnackbar } from 'notistack'
import { registerDatePicker } from "./DatePicker";
import './survey.css';

import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

/*
https://surveyjs.io/form-library/documentation/get-started-react
https://surveyjs.io/form-library/examples/loan-application-form/reactjs#content-code
https://surveyjs.io/form-library/examples/modify-titles-of-survey-elements/reactjs

TODO:
- redirect somewhere on completion?
- pause/submit
- load existing


Called with:
    <SurveyComponent company_id=1 />
    <SurveyComponent survey_id=3 preview=true />

We should probably have two cards, one for running a normal survey and previews
and one for the yearly revision survey? OR Inheritance or....

IDEA:
    - have a separate data loader/saver that calls a survey component that has no axios stuff...

* Should we have a "Save for later" button?
  https://surveyjs.io/form-library/examples/add-custom-navigation-button/jquery

* next/prev button with validation before submit?
  https://github.com/surveyjs/survey-library/issues/519
*/

const showdown = require('showdown');

// N/A anymore?
Serializer.addProperty("question", "popupdescription:text");
Serializer.addProperty("page",     "popupdescription:text");

registerDatePicker();

const DescriptionPopup = (props) => {
    const { title='Description', openPopup, setOpenPopup } = props;

    return (
        <Dialog 
            aria-labelledby="customized-dialog-title"
            open={Boolean(openPopup)}
            maxWidth='sm'
            fullWidth={true}
        >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={() => setOpenPopup(false) }
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent>{openPopup.popupdescription}</DialogContent>
        </Dialog>
    );
};

const SurveyRunner = (props) => {
    const { survey: survey_data, previous: previous_data, saveSurvey: saveSurveyData, preview=false } = props;
    const [ openPopup, setOpenPopup ] = useState(false);
    const descriptionElementId = 'questionDescriptionText';

    //console.log('DEBUG: ', survey_data);

    const converter = new showdown.Converter();
    const survey = new Model(survey_data);
    survey.applyTheme(BorderlessLight);

    if(previous_data){
        //console.log('PREVIOUS: ', previous_data);
        //const data = JSON.parse(previous_data)
        survey.data = previous_data;
    }

    // Setup language, use a useState to get rerender on change?
    let lang = i18next.resolvedLanguage;
    console.log('Survey language: ', lang);
    if(lang === 'SE') lang = 'SV';
    survey.locale = lang.toLowerCase(); 

    survey.onTextMarkdown.add((_, options) => {
        var str = converter.makeHtml(options.text);
        str = str.substring(3);
        str = str.substring(0, str.length - 4);
        options.html = str;
    });

    survey.onAfterRenderQuestion.add((_, opt) => {
        opt.question.descriptionLocation = "hidden";
    });

    survey.onGetQuestionTitleActions.add((_, opt) => {
        if(opt.question.description) {
            opt.titleActions = [
                {
                    title: '(i)',
                    //iconName: 'Info',
                    innerCss: 'btn-more-info',
                    tooltip: opt.question.description,
                    /*
                    action: () => {
                        setOpenPopup(opt.question);
                    },
                    */
                    action: () => {
                        const q = opt.question;
                        if(q.description) {
                          q.descriptionLocation = q.descriptionLocation === "hidden" ? "default" : "hidden";
                        }
                      },
          
                },
            ];
        }
    });

    survey.onValueChanged.add((survey) => {
        //console.log('survey.onValueChanged');
        saveSurveyData(survey, false);
    });
   
    survey.onCurrentPageChanged.add((survey) => {
        //console.log('survey.onCurrentPageChanged');
        //saveSurveyData(survey, false);    // XXX: No need, we do on every question change
    });
   
    survey.onComplete.add((survey) => {
        //console.log('survey.onComplete', preview);
        if(preview){
            enqueueSnackbar('Survey preview completed!', {variant: "success"});
            return;
        }
        saveSurveyData(survey, true);
    });

    return (
        <>
            <DescriptionPopup id={descriptionElementId} openPopup={openPopup} setOpenPopup={setOpenPopup} />
            <Survey model={survey} />
        </>
    );
};

export default SurveyRunner;
